<template lang="html">
  <div id="blog-page">

    <section class="placed-backg breadcrumb-banner-section">
      <div class="container oversized-container">
        <div class="box">
          <div class="page-breadcrumb">
            <ul>
              <li>Home</li>
              <li class="active">Atractivos turísticos</li>
            </ul>
          </div>

          <div class="page-name">Atractivos turísticos</div>
        </div>
      </div>
    </section>

    <div class="container text-section">
      En esta sección encontraras toda la información necesaria para complementar tu viaje con destinos turísticos cercanos a Villa Hidalgo, Jalisco.
    </div>

    <section class="container oversized-container articles-section">
      <div class="row">
        <!-- <div class="col-12 col-s-title">
          <h1 class="text-center title">Visítanos</h1>
        </div> -->

        <div class="col-sm-6 col-lg-4 col-xl-3 col-article-sample-1" v-for="(blog, vInx) in blogs":key="'vInx-'+vInx">
          <router-link class="box-article" :to="'/atractivos-turisticos/'+$root._converToURL(blog.title, blog.id)">
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+blog.image_url+')' }">
              <img src="public/images/shared/empty-2.png">
            </div>

            <div class="box-descr">
              <h5>{{ blog.title }}</h5>

              <!-- <div class="descr" v-html="blog.body"></div> -->
            </div>
          </router-link>
        </div>

      </div>

      <!-- <div class="row mt-3">
        <div class="col-12 col-pagination-sample-1">
          <b-pagination-nav :link-gen="linkGen" :number-of-pages="10" use-router></b-pagination-nav>
        </div>
      </div> -->
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      formSearch: {
        keywords: null
      },

      blogs: [],
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },

    linkGen(pageNum) {
      return {
        name: 'blogPage',
        query: { keywords: this.$route.query.keywords, page: pageNum }
      }
    },

    getBlogs: function(){
        axios.get(tools.url('/api/visitus')).then((response)=>{
            this.blogs = response.data;
        });
    }
  },

  mounted(){
      this.getBlogs();
  }
}
</script>
