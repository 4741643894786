<template lang="html">
  <footer id="footer">

		<div class="container oversized-container">
			<div class="row">

				<div class="col-lg col-footer col-logo">
					<img src="public/images/logo.png" alt="Macro Plaza Las Palmas, Villas Hidalgo">
				</div>

				<div class="col-lg col-footer col-networks">
					<a class="link-net" target="_blank" :href="$root.info.facebook">
						<i class="fab fa-facebook-square"></i>
					</a>
					<a class="link-net" :href="$root.info.instagram">
						<i class="fab fa-instagram"></i>
					</a>
					<!-- <a class="link-net" :href="$root.info.twitter">
						<i class="fab fa-twitter-square"></i>
					</a> -->
				</div>

				<div class="col-sm-6 col-lg col-footer">
					<h5 class="title">Dirección</h5>
					<p v-html="$root.info.address"></p>
				</div>

				<div class="col-sm-6 col-lg col-footer">
					<h5 class="title">Mapa del sitio</h5>

					<p>
						<!-- <router-link to="/">Inicio</router-link><br /> -->
						<router-link to="/tiendas">Tiendas</router-link><br />
						<router-link to="/servicios">Servicios</router-link><br />
						<router-link to="/como-llegar">Como llegar</router-link><br />
						<router-link to="/contacto">Contacto</router-link>
					</p>
				</div>

				<div class="col-lg col-footer">
					<h5 class="title">Legal</h5>

					<p>
						<router-link to="/aviso-de-privacidad">Aviso de Privacidad</router-link><br />
						<!-- <router-link to="/terminos-y-condiciones">Términos y condiciones</router-link> -->
					</p>
					<p class="mt-3">
						Derechos reservados Plaza Las Palmas
					</p>
				</div>

			</div>
		</div>

  </footer>
</template>

<script>
export default {
}
</script>
