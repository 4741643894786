<template lang="html">
  <div class="placed-backg contact-page-" id="page-info-t1">
    <section class="placed-backg breadcrumb-banner-section">
      <div class="container oversized-container">
        <div class="box">
          <div class="page-breadcrumb">
            <ul>
              <li>Home</li>
              <li class="active">Contacto</li>
            </ul>
          </div>

          <div class="page-name">Contacto</div>
        </div>
      </div>
    </section>

    <section class="container oversized-container pt-0 content-section">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-5 col-info">
          <!-- <h1 class="mb-2 page-title">Contacto</h1> -->

          <p class="mb-3 text-center">
            Si deseas contactar una agencia turística que realice viajes frecuentes o eres agencia turística y estas interesado en realizar un tour a nuestro centro comercial, llamanos al <a class="txt-purple" href="tel:4959680886"><strong>495 968 0886</strong></a>.
          </p>

          <p class="text-center">
            Si tienes dudas puedes mandarnos un mensaje a traves de nuestro formulario o un correo a nuestro mail.
          </p>

          <hr />

          <br />
          <h5 class="subtitle-sm">Dirección</h5>
          <p v-html="$root.info.address"></p>

          <br />
          <h5 class="subtitle-sm">Correo electrónico</h5>
          <p>{{ $root.info.email }}</p>
        </div>

        <div class="col-lg-5 col-xl-4 col-info">
          <b-form @submit="onSubmit">
            <b-form-group>
              <b-form-input
                v-model="form.name"
                type="text"
                required
                placeholder="Nombre"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input
                v-model="form.email"
                type="email"
                required
                placeholder="Correo electrónico"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input
                v-model="form.phone"
                type="text"
                required
                placeholder="Teléfono"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input
                v-model="form.state"
                type="text"
                required
                placeholder="Estado"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input
                v-model="form.city"
                type="text"
                required
                placeholder="Ciudad"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-textarea
                v-model="form.msg"
                placeholder="Mensaje"
                required
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <p class="text-center">
              <b-button type="submit" class="btn-send">Enviar</b-button>
            </p>
          </b-form>

        </div>
      </div>
    </section>

    <section class="special-services-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-title">
            <h2>Números de servicio</h2>
          </div>

          <div class="col-md-6 col-button">
            <a class="t-250 btn btn-service" @click="$refs['modal-services-1'].show();"><span>Servicios de Emergencia</span></a>
          </div>

          <div class="col-md-6 col-button">
            <a class="t-250 btn btn-service" @click="$refs['modal-services-2'].show();"><span>Servicios Automotrices</span></a>
          </div>
        </div>
      </div>
    </section>

    <!-- Modal Servicios de emergencia -->
    <b-modal ref="modal-services-1" size="lg" modal-class="modal-services-s1" centered hide-footer title="Servicios de emergencia">
      <div class="row">
        <div class="col-lg-6">
          <h5 class="subtitle">Emergencias</h5>
          <h6 class="tel-title">
            <i class="fas fa-phone"></i> <strong class="tel">*911</strong>
          </h6>
          <p>Atención 24 hrs.</p>

          <h5 class="mt-4 subtitle">Presidencia de Villa Hidalgo</h5>
          <h6 class="tel-title">
            <i class="fas fa-phone"></i> <strong class="tel">495 968 3455</strong>
          </h6>
          <p>Lunes a viernes de 8:00 a 15:00 hrs.</p>
        </div>

        <div class="col-lg-6">
          <h5 class="subtitle">Cruz roja</h5>
          <h6 class="tel-title">
            <i class="fas fa-phone"></i> <strong class="tel">495 968 0540</strong>
          </h6>
          <p>Atención 24 hrs.</p>

          <h5 class="mt-4 subtitle">Protección Civil y Seguridad Pública Villa Hidalgo</h5>
          <h6 class="tel-title">
            <i class="fas fa-phone"></i> <strong class="tel">495 968 1266 y 495 968 0738</strong>
          </h6>
          <p>Atención 24 hrs.</p>
        </div>
      </div>
    </b-modal>
    <!--  -->

    <!-- Modal Servicios automotrices -->
    <b-modal ref="modal-services-2" size="lg" modal-class="modal-services-s1" centered hide-footer title="Servicios Automotrices">
      <div class="row">
        <div class="col-lg-6">
          <h5 class="subtitle">Mecánico diesel Gerardo López</h5>
          <h6 class="tel-title">
            <i class="fas fa-phone"></i> <strong class="tel">495 106 2055</strong>
          </h6>
          <p>Lunes a sabado de 10:00 a 19:00 hrs.</p>

          <h5 class="mt-4 subtitle">Grúas Teocaltiche Manuel Ortiz</h5>
          <h6 class="tel-title">
            <i class="fas fa-phone"></i> <strong class="tel">346-787-2574</strong>
          </h6>
          <!-- <p>Lunes a viernes de 8:00 a 15:00 hrs.</p> -->

          <h5 class="mt-4 subtitle">Refaccionaria el Osso</h5>
          <h6 class="tel-title">
            <i class="fas fa-phone"></i> <strong class="tel">495 968 0318</strong>
          </h6>
          <!-- <p>Lunes a viernes de 8:00 a 15:00 hrs.</p> -->

          <h5 class="mt-4 subtitle">Vehículos Diesel Gerardo López</h5>
          <h6 class="tel-title">
            <i class="fas fa-phone"></i> <strong class="tel">495 1098 818</strong>
          </h6>
          <!-- <p>Lunes a viernes de 8:00 a 15:00 hrs.</p> -->
        </div>

        <div class="col-lg-6">
          <h5 class="subtitle">Autoeléctrico “El güero”</h5>
          <h6 class="tel-title">
            <i class="fas fa-phone"></i> <strong class="tel">495 109 2645</strong>
          </h6>
          <p>Lunes a viernes de 9:00 a 11:00; de 12:00 a 15:00 y de 16:00 a 18:00 hrs.</p>

          <h5 class="mt-4 subtitle">Llantas de Villa</h5>
          <h6 class="tel-title">
            <i class="fas fa-phone"></i> <strong class="tel">495 968 1054 y 495 968 1055</strong>
          </h6>
          <p>
            Lunes, miercoles, jueves y viernes de 9:00 a 15:00 hrs. y 16:00 a 19:00 hrs.<br />
            Martes y sabado de 9:00 a 16:00 hrs.
          </p>

          <h5 class="mt-4 subtitle">Refaccionaria Auto Saul</h5>
          <h6 class="tel-title">
            <i class="fas fa-phone"></i> <strong class="tel">495 968 3325</strong>
          </h6>
          <p>Lunes a sabado de 9:00 a 16:00 hrs.</p>
        </div>
      </div>
    </b-modal>
    <!--  -->

    <!-- <section class="container-fluid px-0 map-section">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d119452.69716917786!2d-103.40545358012501!3d20.67377770932267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b18cb52fd39b%3A0xd63d9302bf865750!2sGuadalajara%2C%20Jal.%2C%20M%C3%A9xico!5e0!3m2!1ses!2sca!4v1627506372341!5m2!1ses!2sca" allowfullscreen="" loading="lazy"></iframe>
    </section> -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        name: null,
        email: null,
        phone: null,
        state: null,
        city: null,
        msg: null
      },

    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    onSubmit(evt) {
      evt.preventDefault();
      //var data=tools.params(form, this.form);
      axios.post(tools.url("/api/contact"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Mensaje enviado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
        // console.log(error.response);
        let errors = error.response.data;
        // console.log(errors);
        this.makeToast('danger', error.response.data.name, 'Error');
        this.makeToast('danger', error.response.data.email, 'Error');
        this.makeToast('danger', error.response.data.phone, 'Error');
        this.makeToast('danger', error.response.data.msg, 'Error');
      });
    }
  },

  beforeMount(){
    if(this.$route.query.p){
      this.form.msg = 'Me intresa el producto '+ this.$route.query.p;
    }
  }
}
</script>
