<template lang="html">
  <div id="description-page">
    <loading-component v-if="isLoading"></loading-component>

    <section class="breadcrumb-section" v-if="!isLoading">
      <div class="container oversized-container">
        <ul>
          <li>Home</li>
          <li>Local</li>
          <li class="active">{{ store.name }}</li>
        </ul>
      </div>
    </section>

    <section class="container oversized-container description-section" id="product-description" v-if="!isLoading">
      <div class="row">

        <div class="col-lg-7 col-gallery">
          <swiper class="swiper-top" :options="galleryOptions" ref="swiperTop">
            <swiper-slide v-for="(row, galIndex) in gallery" :key="galIndex">
              <div class="image-container">
                <div class="img">
                  <img class="placed-backg" src="public/images/shared/empty-rec-1.png" v-bind:style="{ backgroundImage: 'url('+row+')' }">
                  <!-- <v-zoom :img="row"></v-zoom> -->
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>

          <swiper class="swiper-th gallery-thumbs" :options="galleryOptionsThumbs" ref="swiperThumbs" v-if="galleryOptions.loopedSlides > 1">
            <swiper-slide v-for="(thb, galThIndex) in gallery" :key="'galThIndex'+galThIndex">
              <img class="placed-backg image" src="public/images/shared/empty-rec-1.png" v-bind:style="{ backgroundImage: 'url('+thb+')' }">
            </swiper-slide>
          </swiper>
        </div>

        <b-form class="col-lg-5 mt-3 mt-lg-0 col-description">
          <h1 class="name">{{ store.name }}</h1>
          <p class="extra-breadcrumb" style="font-size: 1rem;" v-if="store.location">
            <span>{{ store.location }}</span>
          </p>

          <!-- <div class="row align-items-center box-images">
            <div class="col col-md-6 col-logo">
              <img src="public/images/pages/stores/logo.png">
            </div>

            <div class="col col-md-6 col-loc">
              <img src="public/images/pages/stores/loc.png">
            </div>
          </div> -->

          <p class="mt-3 extra-breadcrumb" v-if="store.phones">
            <span><strong class="f-w-600">Teléfono: </strong> {{ store.phones }}<br /></span>
          </p>
          <p class="mt-2 extra-breadcrumb" v-if="store.whatsapp">
            <span><strong class="f-w-600">WhatsApp: </strong> <a target="_blank" :href="'https://wa.me/521'+store.whatsappLink">{{ store.whatsapp }}</a><br /></span>
          </p>
          <p class="mt-2 extra-breadcrumb" v-if="store.facebook">
            <span><strong class="f-w-600">Facebook: </strong> <a target="_blank" :href="store.facebook">{{ store.facebook }}</a><br /></span>
          </p>
          <p class="mt-2 extra-breadcrumb" v-if="store.email">
            <span><strong class="f-w-600">Email: </strong> <a target="_blank" :href="'mailto:'+store.email">{{ store.email }}</a><br /></span>
          </p>
          <p class="mt-2 extra-breadcrumb" v-if="store.paymentMethods">
            <span><strong class="f-w-600">Formas de pago: </strong> {{ store.paymentMethods }}<br /></span>
          </p>
          <div class="mt-2 extra-breadcrumb" v-if="store.schedules">
            <div><strong class="f-w-600">Horario: </strong>
              <span class="d-block">{{ store.schedules }}</span>
            </div>
          </div>
          <div class="mt-3 mb-2 extra-breadcrumb">
            <div class="row box-categories">
              <div class="col-lg-2 col-label">
                <strong class="f-w-600">Categoria: </strong>
              </div>

              <div class="col-lg col-categories">
                <router-link class="bubble" target="_blank" :to="$root._converToURL(row.name, row.id)" v-for="(row, index) in store.categories" :key="index">
                  {{ row.name }}
                </router-link>
              </div>
            </div>
          </div>

          <h6 class="mt-3 subtitle" v-if="store.description">Descripción</h6>
          <div class="txt-description" v-if="store.description" v-html="store.description"></div>
        </b-form>
      </div>

    </section>

    <section class="stores-section" v-if="stores.length">
      <div class="container oversized-container">

        <div class="row">
          <div class="col-12 col-s-title">
            <h2 class="title">Otras tiendas</h2>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-products">
            <swiper class="swiper swiper-s1" :options="storesOptions">
              <swiper-slide v-for="(s, pdhInx) in stores" :key="'pdhInx-'+pdhInx">
                <div class="col-12 px-0 sample-product shad-prod">
                  <router-link class="outlined-box" :to="'/local/1'">
                    <div class="box-image">
                      <!-- <div class="promo">¡Con promociones!</div> -->

                      <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+s.img+')' }">
                        <img src="public/images/shared/empty.png" >
                      </div>
                    </div>

                    <div class="box-shot-descr">
                      <h6 class="name">{{ s.nombre }}</h6>
                    </div>
                    <p class="p-button">
                      <a class="btn-more">Ver más</a>
                    </p>
                  </router-link>
                </div>
              </swiper-slide>

              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
// import vZoom from 'vue-zoom';
import LoadingComponent from '../shared/loading-component.vue';
export default {
  // components: {vZoom},

  data() {
    return {
      isLoading: true,
      id: null,
      store: [],
      stores: [],
      gallery: [],

      // == Carrusel options ==
      galleryOptions: {
        loop: true,
        loopedSlides: 1,
        speed: 600,
        centeredSlides: true,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false
        // }
      },

      galleryOptionsThumbs: {
        loop: true,
        loopedSlides: 1,
        speed: 600,
        spaceBetween: 15,
        centeredSlides: true,
        slidesPerView: 3,
        touchRatio: 0.2,
        slideToClickedSlide: true,

        breakpoints: {
          1630: {
            slidesPerView: 5
          },
          768: {
            slidesPerView: 3
          },
          1: {
            slidesPerView: 2
          },
        }
      },

      storesOptions: {
        loop: true,
        slidesPerView: 5,
        spaceBetween: 10,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1399: {
            slidesPerView: 5
          },
          1199: {
            slidesPerView: 4
          },
          991: {
            spaceBetween: 10,
            slidesPerView: 3
          },
          575: {
            spaceBetween: 20,
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1
          },
        }
      },
      // == ==
    }
  },

  watch: {
    "$route.params.id" : function(v){
      this.id = this.$root._getURLID(this.$route.params.id);
      this.getContent();
    }
  },

  methods:{
    runGalleryTh(){
      this.$nextTick(() => {
        const swiperTop = this.$refs.swiperTop.$swiper
        const swiperThumbs = this.$refs.swiperThumbs.$swiper
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop
      })
    },

    getContent(){
      this.isLoading = true;

      axios.get(tools.url('/api/stores/' + this.id)).then((response)=>{
        this.store = [];
        this.store = response.data;

        if(this.store.whatsapp){
            this.store.whatsappLink =  this.store.whatsapp.replaceAll(' ', '');
            this.store.whatsappLink =  this.store.whatsappLink.replaceAll('-', '');
        }

        this.gallery = response.data.gallery;
        this.galleryOptions.loopedSlides = this.gallery.length;
        this.galleryOptionsThumbs.loopedSlides = this.gallery.length;

        setTimeout( ()=> {
          this.isLoading = false;
          this.runGalleryTh();
        }, 2000);

      }).catch((error)=>{
        console.log(error);
      });
    },

    getRelated(id){
      axios.get(tools.url('/api/stores/related/'+id)).then((response)=>{
        this.stores = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },
  },

   mounted(){
    this.id = this.$root._getURLID(this.$route.params.id);
    this.getContent();
    this.getRelated(this.id);
  },

  components: {
    LoadingComponent
  },
}
</script>
