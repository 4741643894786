<template lang="html">
  <div id="sketch-page">
    <section class="placed-backg breadcrumb-banner-section">
      <div class="container oversized-container">
        <div class="box">
          <div class="page-breadcrumb">
            <ul>
              <li>Home</li>
              <li class="active">Croquis</li>
            </ul>
          </div>

          <div class="page-name">Croquis</div>
        </div>
      </div>
    </section>

    <section class="sketch-section">
      <div class="container">
        <div class="d-block text-center pb-3">
          <p class="f-w-600"><i class="fas fa-search"></i> Coloque el mouse sobre la imagen para hacer zoom.</p>
        </div>
        <!-- <img class="img-fluid" src="public/images/pages/sketch/sketch.png"> -->
        <v-zoom :img="image" :width="'100%'"></v-zoom>

        <div class="d-block text-center pt-4 pb-3">
          <a class="t-250 btn-download" target="_blank" download :href="image">Descargar croquis</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import vZoom from 'vue-zoom';
export default {
  components: {vZoom},

  data(){
    return{
      index: null,
      image: ''
    }
  },

  methods: {
    getImage: function(){
        axios.get(tools.url('/api/imagesExtra')).then((response)=>{
            this.image = response.data.croquis_imageUrl;
        });
    }
  },

  mounted(){
      this.getImage();
  },
}
</script>
