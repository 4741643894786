<template lang="html">
  <div class="page" id="article-page">

    <div class="container content-section">
      <div class="row">
        <div class="col-12 col-s-title">
          <h1 class="text-center title">Visítanos</h1>
        </div>

        <!-- Artículo actual -->
        <div class="col-lg-8 col-article">
          <div class="box-image">
            <img :src="noticia.image_url">
          </div>
          <h1>{{ noticia.title }}</h1>
          <div class="descr" v-html="noticia.body"></div>
        </div>
        <!--  -->

        <!-- Artículos -->
        <div class="col-lg-4 col-blog">
          <div class="row">
            <div class="col-12 col-article-sample-1 sample-2" v-for="(blog, arInx) in blogs" :key="'ar-'+arInx">
              <router-link class="box-article" :to="'/atractivos-turisticos/1'">
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+blog.image_url+')' }">
                  <img src="public/images/shared/empty-2.png">
                </div>

                <div class="box-descr">
                  <h5>{{ blog.title }}</h5>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data(){
    return{
      noticia: [],
      blogs: [],
      id: null,
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },

    linkGen(pageNum) {
      return {
        name: 'tutorialsPage',
        query: { keywords: this.$route.query.keywords, page: pageNum }
      }
    },

    getBlogs: function(){
        axios.get(tools.url('/api/visitus/'+this.id)).then((response)=>{
            this.noticia = response.data.new;
            this.blogs = response.data.news;
        });
    }
  },

  watch: {
        "$route.params.id" : function(v){
            this.id = this.$root._getURLID(this.$route.params.id);
            this.getBlogs();
        },
    },

  mounted(){
    this.id = this.$root._getURLID(this.$route.params.id);
    this.getBlogs();
  }
}
</script>
