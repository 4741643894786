<template lang="html">
  <div id="location-maps-page">
    <section class="placed-backg breadcrumb-banner-section">
      <div class="container oversized-container">
        <div class="box">
          <div class="page-breadcrumb">
            <ul>
              <li>Home</li>
              <li class="active">Cómo llegar</li>
            </ul>
          </div>

          <div class="page-name">Cómo llegar</div>
        </div>
      </div>
    </section>

    <!-- <section class="breadcrumb-section">
      <div class="container oversized-container">
        <ul>
          <li>Home</li>
          <li>Como llegar</li>
        </ul>
      </div>
    </section> -->

    <section class="location-maps-section">
      <div class="container oversized-container">
        <div class="row">

          <div class="col-12 col-s-title">
            <h2 class="title">Conoce los costos de casetas y aproximado de combustible en la  página oficial de la <a href="http://app.sct.gob.mx/sibuac_internet/ControllerUI?action=cmdEscogeRuta" target="_blank" rel="noopener noreferrer">Secretaría de Comunicaciones y Transportes</a></h2>
          </div>

          <div class="col-12 col-maps">
            <div class="row box-content">
              <div class="col-lg-4 col-info">
                <h6 class="title">Desde el Norte</h6>

                <p>
                  Al visitarnos desde el noreste y noroeste, te recomendamos llegar a la ciudad de Aguascalientes, identifica y toma la salida a Calvillo (Carretera #70), al cruzar con Av. Siglo XXI gira a la izquierda, esa es la carretera federal #71, mantente sobre esta carretera y llegarás a Villa Hidalgo, que se encuentra a 45 km. de distancia.
                </p>

                <table class="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <th>Línea</th>
                      <th>Ciudad de Salida</th>
                      <th>Horario</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <th>Servicio Interestatal</th>
                      <td>Aguascalientes, Ags.</td>
                      <td>
                        Desde las 6:00 AM<br />Cada 30 min.
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h5 class="mt-4 distance">Distancia desde Aguascalientes, Ags. <span class="txt-purple">40 Min.</span></h5>
              </div>

              <div class="col-lg-8 col-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d237117.1937920808!2d-102.57307834871612!3d21.781957939417254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x8429ec143ae4d9fb%3A0x4016978679c5220!2sAguascalientes!3m2!1d21.8852562!2d-102.2915677!4m5!1s0x8429b99a49e58a3f%3A0x92cb0204d9278dc0!2sVilla%20Hidalgo%2C%20Jalisco!3m2!1d21.6769411!2d-102.58917919999999!5e0!3m2!1ses!2smx!4v1641852876186!5m2!1ses!2smx" allowfullscreen="" loading="lazy"></iframe>
              </div>
            </div>

            <div class="row box-content mt-4">
              <div class="col-lg-4 col-info">
                <!-- <div class="box-btns">
                  <a class="btn btn-st1" @click="loc = 'sur'" v-bind:class="{ active : loc == 'sur' }">Desde el sur</a>
                  <a class="btn btn-st1" @click="loc = 'nor'" v-bind:class="{ active : loc == 'nor' }">Desde el norte</a>
                  <a class="btn btn-st1" @click="loc = 'gdl'" v-bind:class="{ active : loc == 'gdl' }">Desde Guadalajara</a>
                </div> -->

                <h6 class="title">Desde el occidente y suroeste</h6>

                <p>
                  Si nos visitas desde el occidente o suroeste toma como referencia la ciudad de Guadalajara, ve a la carretera #80D Guadalajara-Lagos de moreno, al pasar Tepatitlán, sigue por la carretera y dirígete a Jalostotitlán, atraviésalo y avanza por la carretera estatal #215 al terminar ésta, toma la carretera estatal #211 y estarás a 25km de distancia de Villa Hidalgo.
                </p>

                <h5 class="mt-4 distance">Distancia en tiempo desde Guadalajara, Jal. <span class="txt-purple">1:50 Hrs.</span></h5>
              </div>

              <div class="col-lg-8 col-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d952664.1300090917!2d-103.45749616568497!3d21.13869385505469!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x8428b18cb52fd39b%3A0xd63d9302bf865750!2sGuadalajara%2C%20Jalisco!3m2!1d20.659698799999997!2d-103.34960919999999!4m5!1s0x8429b99a49e58a3f%3A0x92cb0204d9278dc0!2sVilla%20Hidalgo%2C%20Jalisco!3m2!1d21.6769411!2d-102.58917919999999!5e0!3m2!1ses!2smx!4v1641855510308!5m2!1ses!2smx" allowfullscreen="" loading="lazy"></iframe>
              </div>
            </div>

            <div class="row box-content mt-4">
              <div class="col-lg-4 col-info">
                <h6 class="title">Desde el centro y sureste</h6>

                <p>
                  Al visitarnos desde el centro y sureste, te recomendamos llegar a la ciudad de León, Gto. partiendo de este punto toma la carretera #45D León-Aguascalientes, 12 km. antes de llegar a la ciudad dirígete a la carretera #2 (A mano izquierda veras el aeropuerto), cruzarás la localidad Tanque de los Jiménez, al término de esta carretera da vuelta a mano izquierda continuando por la carretera federal #71 Aguascalientes-Villa Hidalgo, y estarás 20 min de Villa Hidalgo.
                </p>

                <h5 class="mt-4 distance">Distancia en tiempo desde León, Gto. <span class="txt-purple">2:15 Hrs</span></h5>
              </div>

              <div class="col-lg-8 col-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d475302.37287060317!2d-102.4188414387197!3d21.45675065584171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x842bbeffd6e6e721%3A0x606d95ec61a54327!2sLe%C3%B3n%2C%20Guanajuato!3m2!1d21.125007699999998!2d-101.6859605!4m5!1s0x8429b99a49e58a3f%3A0x92cb0204d9278dc0!2sVilla%20Hidalgo%2C%20Jalisco!3m2!1d21.6769411!2d-102.58917919999999!5e0!3m2!1ses!2smx!4v1641855263323!5m2!1ses!2smx" allowfullscreen="" loading="lazy"></iframe>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      loc: 'sur',
    }
  }
}
</script>
