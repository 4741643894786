<template lang="html">
  <div id="toilets-page">

    <section class="placed-backg breadcrumb-banner-section">
      <div class="container oversized-container">
        <div class="box">
          <div class="page-breadcrumb">
            <ul>
              <li>Home</li>
              <li class="active">Sanitarios</li>
            </ul>
          </div>

          <div class="page-name">Sanitarios</div>
        </div>
      </div>
    </section>

    <div class="container text-center text-section">
      <h5>
        Contamos con 4 áreas de sanitarios.
      </h5>
    </div>

    <section class="container oversized-container gallery-section">
      <div class="row align-items-center">
        <div class="col-lg-6 col-sketch">
          <img :src="image">
        </div>

        <div class="col-lg-6 col-toilets">
          <div class="row">
            <div class="col-6 col-lg-6 col-photo" v-for="(image, imageIndex) in gallery" :key="imageIndex">
              <div class="t-250 placed-backg box" v-bind:style="{ backgroundImage: 'url('+image+')' }" @click="index = imageIndex">
                <img src="public/images/pages/parking-lot/empty.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <CoolLightBox
      :items="gallery"
      :index="index"
      :effect="'fade'"
      @close="index = null">
    </CoolLightBox>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  data(){
    return{
      gallery: [
        'public/images/pages/toilets/sanitarios-1.jpg',
        'public/images/pages/toilets/sanitarios-2.jpg',
        'public/images/pages/toilets/sanitarios-3.jpg',
        'public/images/pages/toilets/sanitarios-4.jpg',
      ],
      index: null,
      image: ''
    }
  },

  methods: {
    getImage: function(){
        axios.get(tools.url('/api/imagesExtra')).then((response)=>{
            this.image = response.data.servicios_imageUrl;
        });
    }
  },

  mounted(){
      this.getImage();
  },

  components: {
    CoolLightBox,
  },
}
</script>
