<template lang="html">
  <div id="parking-lot-page">

    <section class="placed-backg breadcrumb-banner-section">
      <div class="container oversized-container">
        <div class="box">
          <div class="page-breadcrumb">
            <ul>
              <li>Home</li>
              <li class="active">Estacionamiento</li>
            </ul>
          </div>

          <div class="page-name">Estacionamiento</div>
        </div>
      </div>
    </section>

    <div class="container text-center text-section">
      <h4 class="mb-1 f-w-700">Somos PARADERO AUTORIZADO DE AUTOBUSES.</h4>
      <h5>
        Contamos con amplio estacionamiento, seguro, con monitoreo de circuito cerrado y totalmente GRATIS las 24 horas del día, con una capacidad de 800 espacios para autobuses y 3,000 para vehículos particulares.
      </h5>
    </div>

    <section class="container oversized-container gallery-section">
      <div class="row">
        <div class="col-6 col-lg-4 col-xl-3 col-photo" v-for="(image, imageIndex) in gallery" :key="imageIndex">
          <div class="t-250 placed-backg box" v-bind:style="{ backgroundImage: 'url('+image+')' }" @click="index = imageIndex">
            <img src="public/images/pages/parking-lot/empty.png">
          </div>
        </div>
      </div>
    </section>

    <CoolLightBox
      :items="gallery"
      :index="index"
      :effect="'fade'"
      @close="index = null">
    </CoolLightBox>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  data(){
    return{
      gallery: [
        'public/images/pages/parking-lot/estacionamiento-1.jpg',
        'public/images/pages/parking-lot/estacionamiento-2.jpg',
        'public/images/pages/parking-lot/estacionamiento-3.jpg',
        'public/images/pages/parking-lot/estacionamiento-4.jpg',
        'public/images/pages/parking-lot/estacionamiento-5.jpg',
        'public/images/pages/parking-lot/estacionamiento-6.jpg',
        'public/images/pages/parking-lot/estacionamiento-7.jpg',
        'public/images/pages/parking-lot/estacionamiento-8.jpg',
        'public/images/pages/parking-lot/estacionamiento-9.jpg',
        'public/images/pages/parking-lot/estacionamiento-10.jpg',
        'public/images/pages/parking-lot/estacionamiento-11.jpg',
        'public/images/pages/parking-lot/estacionamiento-12.jpg',
        'public/images/pages/parking-lot/estacionamiento-13.jpg',
        'public/images/pages/parking-lot/estacionamiento-14.jpg',
        'public/images/pages/parking-lot/estacionamiento-15.jpg',
        'public/images/pages/parking-lot/estacionamiento-16.jpg',
        'public/images/pages/parking-lot/estacionamiento-17.jpeg',
        'public/images/pages/parking-lot/estacionamiento-18.jpeg',
        'public/images/pages/parking-lot/estacionamiento-19.jpeg',
        'public/images/pages/parking-lot/estacionamiento-20.jpg',
        'public/images/pages/parking-lot/estacionamiento-21.jpg',
        'public/images/pages/parking-lot/estacionamiento-22.jpg',
        'public/images/pages/parking-lot/estacionamiento-23.jpg',
        'public/images/pages/parking-lot/estacionamiento-24.jpg',
        'public/images/pages/parking-lot/estacionamiento-25.jpg',
        'public/images/pages/parking-lot/estacionamiento-26.jpg',
        'public/images/pages/parking-lot/estacionamiento-27.jpg',
        'public/images/pages/parking-lot/estacionamiento-28.jpg',
      ],
      index: null
    }
  },

  methods: {},

  mounted(){},

  components: {
    CoolLightBox,
  },
}
</script>
