<template lang="html">
  <div id="results-page">

    <section class="placed-backg breadcrumb-banner-section">
      <div class="container oversized-container">
        <div class="box">
          <div class="page-breadcrumb">
            <ul>
              <li>Home</li>
              <li class="active">Tiendas</li>
            </ul>
          </div>

          <div class="page-name">Tiendas</div>
        </div>
      </div>
    </section>

    <section class="container oversized-container products-section">

      <div class="row align-items-start">

        <!-- Mobile filters -->
        <!-- <div class="col-12 col-filters-mobile">
          <div class="bg-filter-mv" v-if="showFilters == true" @click="showFilters = false"></div>
          <a class="btn-filters" @click="showFilters = !showFilters">
            <i class="fas fa-filter"></i><span>Ver filtros</span>
          </a>
        </div> -->
        <!--  -->

        <!-- Col filters -->
        <!-- <div class="col-lg col-filters" v-bind:class="{ 'show-filters': showFilters == true }">
          <h4 class="_title">
            <span>Filtros de Búsqueda</span>
            <a class="flex-center-xy btn-hide" @click="showFilters = false"><i class="fas fa-chevron-left"></i></a>
          </h4>

          <b-form class="filters-container" @submit="onSubmitFilters">
            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> Categorias</a>

              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  v-model="filtersForm.colors"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-2">
                  <b-form-checkbox value="Dama">Dama</b-form-checkbox>
                  <b-form-checkbox value="Caballero">Caballero</b-form-checkbox>
                  <b-form-checkbox value="Niño">Niño</b-form-checkbox>
                  <b-form-checkbox value="Niña">Niña</b-form-checkbox>
                  <b-form-checkbox value="Blancos">Blancos</b-form-checkbox>
                  <b-form-checkbox value="comida">Área de comida</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
          </b-form>
        </div> -->
        <!--  -->

        <!-- Resultados en columnas -->
        <div class="col-lg">
          <div class="row sample-products-wrapper xxl-4e" v-if="!isLoading">
            <div class="col-sm-6 col-md-6 col-xl-4 sample-product shad-prod" v-for="(s, sInx) in stores" :key="'sInx-'+sInx">
              <router-link class="outlined-box" :to="'/local/'+$root._converToURL(s.name, s.id)">
                <div class="box-image">
                  <!-- <div class="promo">¡Con promociones!</div> -->

                  <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+s.img+')' }">
                    <img src="public/images/shared/empty-rec-1.png" >
                  </div>
                </div>

                <div class="box-shot-descr">
                  <h6 class="name">{{ s.name }}</h6>
                </div>
                <p class="p-button">
                  <a class="btn-more">Ver más</a>
                </p>
              </router-link>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-center" v-if="!stores.length && !isLoading">
              <h5>No se encontraron resultados</h5>
            </div>

            <div class="col-12" v-if="isLoading">
              <loading-component></loading-component>
            </div>
          </div>
        </div>
        <!--  -->

      </div>
    </section>

  </div>
</template>

<script>
import LoadingComponent from '../shared/loading-component.vue';
export default {
  data(){
    return{
      isLoading: false,
      showFilters: false,

      filtersForm: {
        keywords: '',
      },

      stores: [],
    }
  },

  methods: {
    onSubmitFilters(evt) {
      evt.preventDefault();
    },

    getStores: function(){
      this.isLoading = true;

      axios.get(tools.url('/api/stores')).then((response)=>{
        this.stores = response.data;
        this.isLoading = false;
      }).catch((error)=>{
        console.log(error);
      })
    },

    getSearch(){
      this.isLoading = true;
      axios.post(tools.url('/api/search'),this.filters).then((response)=>{
        this.stores = response.data;
        this.isLoading = false;
      }).catch((error)=>{
        console.log(error);
      });
    },
  },

  watch:{
    // '$route.query.keywords':function(){
    //   this.filters.keywords = this.$route.query.keywords;
    //   this.isLoading = true;
    //   this.getSearch();
    // }
  },

  mounted(){
    //setTimeout( ()=>{ this.isLoading = false; }, 2000); // ejemplo de "loading" despues de cargar

    // if(this.$route.query.keywords){
    //   this.filters.keywords = this.$route.query.keywords;
    //   this.getSearch();
    // }else{
    //   this.getStores();
    // }

    this.getStores();
  },

  components: {
    LoadingComponent
  },
}
</script>
