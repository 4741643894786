<template lang="html">
  <div id="hotels-v2-page">

    <section class="placed-backg breadcrumb-banner-section">
      <div class="container oversized-container">
        <div class="box">
          <div class="page-breadcrumb">
            <ul>
              <li>Home</li>
              <li class="active">Alojamiento</li>
            </ul>
          </div>

          <div class="page-name">Alojamiento</div>
        </div>
      </div>
    </section>

    <section class="location-hotel-section">
      <div class="container oversized-container">
        <div class="row">

          <div class="col-12 col-maps">
            <div class="row box-content" v-for="(h, hpInx) in hotels" :key="'hpInx-'+hpInx">
              <div class="col-lg-4 col-info">
                <!-- <div class="box-btns">
                  <a class="btn btn-st1" @click="loc = 'sur'" v-bind:class="{ active : loc == 'sur' }">Desde el sur</a>
                  <a class="btn btn-st1" @click="loc = 'nor'" v-bind:class="{ active : loc == 'nor' }">Desde el norte</a>
                  <a class="btn btn-st1" @click="loc = 'gdl'" v-bind:class="{ active : loc == 'gdl' }">Desde Guadalajara</a>
                </div> -->

                <h6 class="title">{{ h.name }}</h6>
                <p>
                  <strong>Domicilio: </strong> {{ h.addr }}<br />
                  <strong>Teléfonos: </strong> {{ h.phones }}<br />
                  <span v-if="h.fb"><strong>Facebook: </strong> <a target="_blank" :href="h.fbLink">{{ h.fb }}</a><br /></span>
                  <span v-if="h.email"><strong>Email: </strong> <a :href="'mailto:'+h.email">{{ h.email }}</a></span>
                </p>

                <br />
                <h6 class="subtitle">Costo aprox. en ocupación cuadruple</h6>
                <p>
                  {{ h.cost }}
                </p>

                <br />
                <h6 class="subtitle">Amenidades</h6>
                <p>
                  {{ h.ameni }}
                </p>
              </div>

              <div class="col-lg-8 col-map">
                <iframe :src="h.map" allowfullscreen="" loading="lazy"></iframe>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      hotels: [
        { name: 'Hotel Villa Esparza', addr: 'Solidaridad #10, Villa Hidalgo, Jal.',
          phones: '495 968 0963 y 495 968 3012', fb: 'Hotel Villa Esparza',
          fbLink: 'https://es-la.facebook.com/login/?next=https%3A%2F%2Fes-la.facebook.com%2Fpages%2Fcategory%2FHotel%2FHotel-Villa-Esparza-200455030013455%2F',
          email: null,
          cost: '$550.00 MXN Costo por noche.',
          ameni: 'Estacionamiento, Cable, TV, WIFI, Agua caliente, Restaurante, Bar, Boutique, Internet público.',
          map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3707.625172115406!2d-102.59215858468389!3d21.67843367065666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429b99729eafbb3%3A0xcec7738cd7d542d6!2sHotel%20Villa%20Esparza!5e0!3m2!1ses!2smx!4v1643124474287!5m2!1ses!2smx',
        },
        { name: 'Hotel Adán y Eva', addr: 'Venustiano Carranza #4, Villa Hidalgo, Jal.',
          phones: '495 968 0015', fb: 'Hotel Adán y Eva',
          fbLink: 'https://es-la.facebook.com/login/?next=https%3A%2F%2Fes-la.facebook.com%2Fpages%2Fcategory%2FInn%2FHOTEL-ADAN-Y-EVA-1393887424268883%2F',
          email: null,
          cost: '$500.00MNX Costo por noche.',
          ameni: 'Suite, Internet, Restaurante, Estacionamiento',
          map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3707.745940057432!2d-102.5907299846839!3d21.67373827082148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429b990154b674f%3A0x336bd1fb4ffd743a!2sHotel%20Adan%20Y%20Eva!5e0!3m2!1ses!2smx!4v1643122867579!5m2!1ses!2smx',
        },
        { name: 'Hotel Los Vergeles', addr: 'Nayarit #349, Villa Hidalgo, Jal.',
          phones: '495 968 1377 y 495 968 1317', fb: 'Hotel Vergeles',
          fbLink: 'https://es-la.facebook.com/login/?next=https%3A%2F%2Fes-la.facebook.com%2Fpages%2Fcategory%2FHotel-Resort%2FHOTEL-Los-Vergeles-1671905366193066%2F',
          email: 'hotelvergeles@hotmail.com',
          cost: '$650.00MNX Costo por noche',
          ameni: 'Restaurante, Internet, Sala de juntas, Vapor, Estacionamiento',
          map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3707.587311587082!2d-102.58492838468389!3d21.679905470604965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429b9993e7e864f%3A0x503202d5e4465a4!2sHotel%20los%20Vergeles!5e0!3m2!1ses!2smx!4v1643123368144!5m2!1ses!2smx',
        },
        { name: 'Hotel Villa Palace', addr: 'Venustiano Carranza 321, Centro,Villa Hidalgo, Jal.',
          phones: '495 968 0767', fb: 'Hotel Villa Palace "Villahidalgo jal."',
          fbLink: 'https://www.facebook.com/pages/hotel-villa-palace-villahidalgo-jal/296768810357985',
          email: null,
          cost: '$500.00MNX Costo por noche',
          ameni: 'Internet, Tv, Estacionamiento',
          map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3707.6071682189818!2d-102.59002588468387!3d21.67913357063211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429b9975b1675cf%3A0x265066fa67035881!2sHotel%20Villa%20Palace!5e0!3m2!1ses!2smx!4v1643123640676!5m2!1ses!2smx',
        },
        { name: 'Hotel La Paz', addr: 'La Paz 6, Centro, 47250 Villa Hidalgo, Jal.',
          phones: '495 968 0302', fb: 'Hotel La Paz Villa Hidalgo Jalisco.',
          fbLink: 'https://m.facebook.com/profile.php?id=530369643657714',
          email: null,
          cost: '$450.00MNX Costo por noche',
          ameni: 'Internet, Tv, Estacionamiento',
          map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3707.6127142703276!2d-102.5908296846839!3d21.678917970639713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429b997387228f7%3A0xe0fbca3468a52e3a!2sHotel%20La%20Paz!5e0!3m2!1ses!2smx!4v1643124168200!5m2!1ses!2smx',
        },
      ],
    }
  }
}
</script>
