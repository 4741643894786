<template lang="html">
  <header id="header">

    <div class="header-content">
      <b-navbar toggleable="lg" type="light" variant="default">
        <div class="container oversized-container">

          <b-navbar-brand to="/">
            <img class="logo" src="public/images/logo.png">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"><i class="fas fa-bars"></i></b-navbar-toggle>

          <b-collapse id="nav-collapse" class="header-navigation" is-nav>
            <b-navbar-nav class="header-menu">
              <b-nav-item class="simple-item item-inicio" to="/"><strong>Inicio</strong></b-nav-item>
              <b-nav-item class="simple-item" to="/locales"><strong>Tiendas</strong></b-nav-item>
              <!-- <b-nav-item class="simple-item" to="/servicios"><strong>Servicios</strong></b-nav-item> -->
              <b-nav-item class="simple-item" to="/croquis"><strong>Croquis</strong></b-nav-item>
              <b-nav-item-dropdown class="simple-item" right>
                <template #button-content>
                  <strong>Servicios</strong>
                </template>
                <b-dropdown-item to="/servicios/restaurantes">Restaurantes</b-dropdown-item>
                <b-dropdown-item to="/servicios/estacionamiento">Estacionamiento</b-dropdown-item>
                <b-dropdown-item to="/servicios/sanitarios">Sanitarios</b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item class="simple-item jes-green" to="/como-llegar">Cómo llegar</b-nav-item>
              <b-nav-item class="simple-item jes-green" to="/alojamiento">Alojamiento</b-nav-item>
              <b-nav-item class="simple-item jes-green" to="/atractivos-turisticos">Atractivos turísticos</b-nav-item>
              <b-nav-item class="simple-item" to="/contacto"><strong>Contacto</strong></b-nav-item>

              <!-- <li class="nav-item simple-item d-lg-none">
                <div class="nav-link">
                  <hr class="my-2" />
                </div>
              </li>
              <b-nav-item class="simple-item cart-item d-lg-none" href="#">Mi carrito <i class="num">4</i></b-nav-item>
              <b-nav-item class="simple-item d-lg-none" to="/login"><i class="far fa-user user-icon"></i>&nbsp; Iniciar sesión</b-nav-item>
              <b-nav-item class="simple-item d-lg-none" to="/registrarse"><i class="fas fa-user-plus"></i> Registrarse</b-nav-item> -->
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-nav-form class="search-form order-1 order-lg-0" @submit.prevent="searchForm">
                <b-form-input size="sm" class="mr-sm-2" placeholder="Buscar local" v-model="search.keywords"></b-form-input>
                <b-button class="btn-search" type="submit"></b-button>
              </b-nav-form>

              <!-- <b-nav-item class="simple-item" to="">X</b-nav-item> -->

              <!-- <b-nav-item-dropdown class="extra-menu order-0 order-lg-1 d-none d-lg-flex" right>
                <template slot="button-content" class="xsd"><i class="far fa-user user-icon"></i></template>
                <b-dropdown-item to="/registrarse">Registrarse</b-dropdown-item>
                <b-dropdown-item to="/login">Iniciar sesión</b-dropdown-item>
              </b-nav-item-dropdown> -->
            </b-navbar-nav>
          </b-collapse>

        </div>
      </b-navbar>
    </div>

  </header>
</template>

<script>
export default {
  data(){
    return{
      search: {
        keywords: null
      }
    }
  },

  methods:{
    searchForm(){
      this.$router.push({path: '/buscar', query: {keywords:this.search.keywords}});
    }
  }
}
</script>
