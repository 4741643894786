<template lang="html">
  <div class="page" id="home-page">

    <!-- Carousels -->
    <section class="banners-section">
      <!-- Desktop -->
      <swiper class="swiper-s2 d-none d-lg-block" :options="bannersOptions">
        <swiper-slide v-for="(banner,bnhInx) in banners.pc" :key="'bnhInx'+bnhInx">
          <a class="placed-backg d-block" :href="banner.url" v-bind:style="{ backgroundImage: 'url('+banner.image+')' }">
            <img class="w-100" src="public/images/pages/home/banner-a.png">
          </a>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <!-- Mobile -->
      <swiper class="swiper-s2 d-lg-none" :options="bannersOptions">
        <swiper-slide v-for="(banner,bnhmInx) in banners.movil" :key="'bnhmInx-'+bnhmInx">
          <a class="placed-backg d-block" :href="banner.url" v-bind:style="{ backgroundImage: 'url('+banner.image+')' }">
            <img class="w-100" src="public/images/pages/home/banner-a-m.png">
          </a>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>
    <!--  -->

    <!-- About  -->
    <section class="container oversized-container about-section">
      <div class="row">
        <div class="col-lg-6 col-media">
          <!-- <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/about-us.jpg)' }"></div> -->
          <iframe class="video" src="https://www.youtube.com/embed/T6ObeUBO9do?playlist=T6ObeUBO9do&loop=1&rel=0&showinfo=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div class="col-lg-6 col-info">
          <p class="mb-4 text-center">
            <img src="public/images/logo.png">
          </p>

          <div class="row box">
            <div class="col col-text">
              <h5>Tours todo el año</h5>

              <p>
                Te contactamos con la agencia turística u organizador más cercano.<br />
                Incentivos para organizadores y agencias.
              </p>
            </div>

            <div class="col col-icon">
              <i class="fal fa-map-marker-alt"></i>
            </div>
          </div>

          <div class="row box">
            <div class="col col-text">
              <h5>Mayoreo</h5>

              <p>
                Precios especiales de mayoreo<br />
                Envíos a toda la república<br />
                Fabricantes directos<br />
                Los precios más bajos<br />
                Centro de distribución para toda la república.
              </p>
            </div>

            <div class="col col-icon">
              <i class="fal fa-list"></i>
            </div>
          </div>

          <div class="row box">
            <div class="col col-text">
              <h5>Horario</h5>

              <p>
                Lunes a viernes de 9am a 7pm,<br />
                Sábado 4am a 7pm,<br />
                Domingo 8am a 5pm.
              </p>
            </div>

            <div class="col col-icon">
              <i class="fal fa-clock"></i>
            </div>
          </div>

        </div>
      </div>
    </section>
    <!--  -->

    <!-- Novedades / Blog -->
    <section class="container oversized-container articles-section">
      <div class="row">
        <div class="col-12 col-s-title">
          <h2 class="title">Novedades</h2>
        </div>

        <div class="col-12 px-0">
          <swiper class="swiper" :options="blogOptions">
            <swiper-slide v-for="(a, bhInx) in novedades" :key="'bhInx-'+bhInx">
              <div class="col-12 col-article-sample-1">
                <router-link class="box-article" :to="'/novedades/'+ $root._converToURL(a.title, a.id)">
                  <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+a.image_url+')' }">
                    <img src="public/images/shared/empty-2.png">
                  </div>

                  <div class="box-descr">
                    <h5>{{ a.title }}</h5>
                    <!-- <div class="descr" v-html="a.body"></div> -->
                  </div>
                </router-link>
              </div>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Cómo llegar -->
    <section class="location-maps-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 col-s-title">
            <h2 class="title">Cómo llegar</h2>
          </div>

          <div class="col-12 col-maps">
            <div class="row box-content" v-if="loc == 'sur'">
              <div class="col-lg-4 col-info">
                <div class="box-btns">
                  <a class="btn btn-st1" @click="loc = 'nor'" v-bind:class="{ active : loc == 'nor' }">Desde el norte</a>
                  <a class="btn btn-st1" @click="loc = 'gdl'" v-bind:class="{ active : loc == 'gdl' }">Desde el occidente y suroeste</a>
                  <a class="btn btn-st1" @click="loc = 'sur'" v-bind:class="{ active : loc == 'sur' }">Desde el centro y sureste</a>
                </div>

                <h6 class="title">Desde el centro y sureste</h6>

                <p>
                  Al visitarnos desde el centro y sureste, te recomendamos llegar a la ciudad de León, Gto. partiendo de este punto toma la carretera #45D León-Aguascalientes, 12 km. antes de llegar a la ciudad dirígete a la carretera #2 (A mano izquierda veras el aeropuerto), cruzarás la localidad Tanque de los Jiménez, al término de esta carretera da vuelta a mano izquierda continuando por la carretera federal #71 Aguascalientes-Villa Hidalgo, y estarás 20 min de Villa Hidalgo.
                </p>

                <h5 class="mt-3 distance">Distancia en tiempo desde León, Gto. <span class="txt-purple">2:15 Hrs</span></h5>
              </div>

              <div class="col-lg-8 col-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d475302.37287060317!2d-102.4188414387197!3d21.45675065584171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x842bbeffd6e6e721%3A0x606d95ec61a54327!2sLe%C3%B3n%2C%20Guanajuato!3m2!1d21.125007699999998!2d-101.6859605!4m5!1s0x8429b99a49e58a3f%3A0x92cb0204d9278dc0!2sVilla%20Hidalgo%2C%20Jalisco!3m2!1d21.6769411!2d-102.58917919999999!5e0!3m2!1ses!2smx!4v1641855263323!5m2!1ses!2smx" allowfullscreen="" loading="lazy"></iframe>
              </div>
            </div>

            <div class="row box-content" v-if="loc == 'nor'">
              <div class="col-lg-4 col-info">
                <div class="box-btns">
                  <a class="btn btn-st1" @click="loc = 'nor'" v-bind:class="{ active : loc == 'nor' }">Desde el norte</a>
                  <a class="btn btn-st1" @click="loc = 'gdl'" v-bind:class="{ active : loc == 'gdl' }">Desde el occidente y suroeste</a>
                  <a class="btn btn-st1" @click="loc = 'sur'" v-bind:class="{ active : loc == 'sur' }">Desde el centro y sureste</a>
                </div>

                <h6 class="title">Desde el norte</h6>

                <p>
                  Al visitarnos desde el noreste y noroeste, te recomendamos llegar a la ciudad de Aguascalientes, identifica y toma la salida a Calvillo (Carretera #70), al cruzar con Av. Siglo XXI gira a la izquierda, esa es la carretera federal #71, mantente sobre esta carretera y llegarás a Villa Hidalgo, que se encuentra a 45 km. de distancia.
                </p>

                <table class="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <th>Línea</th>
                      <th>Ciudad de Salida</th>
                      <th>Horario</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <th>Servicio Interestatal</th>
                      <td>Aguascalientes, Ags.</td>
                      <td>
                        Desde las 6:00 AM<br />Cada 30 min.
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h5 class="mt-3 distance">Distancia desde Aguascalientes, Ags. <span class="txt-purple">40 Min.</span></h5>
              </div>

              <div class="col-lg-8 col-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d237117.1937920808!2d-102.57307834871612!3d21.781957939417254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x8429ec143ae4d9fb%3A0x4016978679c5220!2sAguascalientes!3m2!1d21.8852562!2d-102.2915677!4m5!1s0x8429b99a49e58a3f%3A0x92cb0204d9278dc0!2sVilla%20Hidalgo%2C%20Jalisco!3m2!1d21.6769411!2d-102.58917919999999!5e0!3m2!1ses!2smx!4v1641852876186!5m2!1ses!2smx" allowfullscreen="" loading="lazy"></iframe>
              </div>
            </div>

            <div class="row box-content" v-if="loc == 'gdl'">
              <div class="col-lg-4 col-info">
                <div class="box-btns">
                  <a class="btn btn-st1" @click="loc = 'nor'" v-bind:class="{ active : loc == 'nor' }">Desde el norte</a>
                  <a class="btn btn-st1" @click="loc = 'gdl'" v-bind:class="{ active : loc == 'gdl' }">Desde el occidente y suroeste</a>
                  <a class="btn btn-st1" @click="loc = 'sur'" v-bind:class="{ active : loc == 'sur' }">Desde el centro y sureste</a>
                </div>

                <h6 class="title">Desde el occidente y suroeste</h6>

                <p>
                  Si nos visitas desde el occidente o suroeste toma como referencia la ciudad de Guadalajara, ve a la carretera #80D Guadalajara-Lagos de moreno, al pasar Tepatitlán, sigue por la carretera y dirígete a Jalostotitlán, atraviésalo y avanza por la carretera estatal #215 al terminar ésta, toma la carretera estatal #211 y estarás a 25km de distancia de Villa Hidalgo.
                </p>

                <!-- <table class="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <th>Línea</th>
                      <th>Ciudad de Salida</th>
                      <th>Horario</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <th>Rojos de los Altos</th>
                      <td>Guadalajara, Jal.</td>
                      <td>
                        Desde las 6:00 AM<br />Cada 2 Horas.
                      </td>
                    </tr>
                  </tbody>
                </table> -->

                <h5 class="mt-3 distance">Distancia desde Guadalajara, Jalisco. <span class="txt-purple">1:50 Hrs.</span></h5>
              </div>

              <div class="col-lg-8 col-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d952664.1300090917!2d-103.45749616568497!3d21.13869385505469!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x8428b18cb52fd39b%3A0xd63d9302bf865750!2sGuadalajara%2C%20Jalisco!3m2!1d20.659698799999997!2d-103.34960919999999!4m5!1s0x8429b99a49e58a3f%3A0x92cb0204d9278dc0!2sVilla%20Hidalgo%2C%20Jalisco!3m2!1d21.6769411!2d-102.58917919999999!5e0!3m2!1ses!2smx!4v1641855510308!5m2!1ses!2smx" allowfullscreen="" loading="lazy"></iframe>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Tiendas -->
    <section class="stores-section">
      <div class="container oversized-container">

        <div class="row">
          <div class="col-12 col-s-title">
            <h2 class="title">Conoce las tiendas disponibles</h2>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-products">
            <swiper class="swiper swiper-s1" :options="storesOptions">
              <swiper-slide v-for="(s, pdhInx) in stores" :key="'pdhInx-'+pdhInx">
                <div class="col-12 px-0 sample-product shad-prod">
                  <router-link class="outlined-box" :to="'/local/'+$root._converToURL(s.name, s.id)">
                    <div class="box-image">
                      <!-- <div class="promo">¡Con promociones!</div> -->

                      <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+s.img+')' }">
                        <img src="public/images/shared/empty.png" >
                      </div>
                    </div>

                    <div class="box-shot-descr">
                      <h6 class="name">{{ s.name }}</h6>
                    </div>
                    <p class="p-button">
                      <a class="btn-more">Ver más</a>
                    </p>
                  </router-link>
                </div>
              </swiper-slide>

              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>

      </div>
    </section>
    <!--  -->

  </div>
</template>

<script>
export default {
  data(){
    return{
      banners: [],

      loc: 'nor',

      novedades: [],

      stores: [],

      // == Carousel options ==
      bannersOptions: {
        loop: true,
        speed: 700,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },
      },

      blogOptions: {
        slidesPerView: 3,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        breakpoints: {
          992: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 1,
          },
        }
      },

      storesOptions: {
        loop: true,
        slidesPerView: 5,
        spaceBetween: 10,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1399: {
            slidesPerView: 5
          },
          1199: {
            slidesPerView: 4
          },
          991: {
            spaceBetween: 10,
            slidesPerView: 3
          },
          575: {
            spaceBetween: 20,
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1
          },
        }
      },
      // == ==
    }
  },

  methods: {
    getHome: function(){
        axios.get(tools.url('/api/home')).then(({data}) =>{
            this.banners = data.banners;
            this.stores = data.stores;
            this.novedades = data.novedades;
        });
    }
  },

  mounted(){
    this.getHome();
  }
}
</script>
