import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
    //mode: 'history',
  	routes:[
      { path: '/', component: require(page+'home.vue').default },
      { path: '/como-llegar', component: require(page+'location-maps/index.vue').default, meta:{title:"Como llegar"}},
      { path: '/alojamiento', component: require(page+'hotels/index-v2.vue').default, meta:{title:"Alojamiento"}},

      { path: '/croquis', component: require(page+'sketch/index.vue').default, meta:{title:"Croquis"}},
	    { path: '/contacto', component: require(page+'contact/index.vue').default, meta:{title:"Contacto"}},

      // { path: '/blog', name: "blogPage", component: require(page+'blog/index.vue').default, meta:{title:"Blog"}},
      { path: '/novedades/:id?', component: require(page+'blog/detail.vue').default, meta:{title:"Novedades"}},

      { path: '/atractivos-turisticos', component: require(page+'visit-us/index.vue').default, meta:{title:"Visítanos"}},
      { path: '/atractivos-turisticos/:id?', component: require(page+'visit-us/detail.vue').default, meta:{title:"Visítanos"}},

      { path: '/locales', component: require(page+'stores/results.vue').default, meta:{title:"Tiendas"}},
      { path: '/buscar', component: require(page+'stores/search.vue').default, meta:{title:"Busqueda"}},
      { path: '/local/:id?', component: require(page+'stores/description.vue').default, meta:{title:"Tienda"}},

      { path: '/servicios/restaurantes', component: require(page+'services/restaurants.vue').default, meta:{title:"Restaurantes"}},
      { path: '/servicios/restaurantes/:id?', component: require(page+'services/description.vue').default, meta:{title:"Restaurante"}},
      { path: '/servicios/estacionamiento', component: require(page+'services/parking-lot.vue').default, meta:{title:"Estacionamiento"}},
      { path: '/servicios/sanitarios', component: require(page+'services/toilets.vue').default, meta:{title:"Sanitarios"}},

      { path: '/aviso-de-privacidad', component: require(page+'text-page/aviso.vue').default, meta:{title:"Aviso de Privacidad"}},
      { path: '/terminos-y-condiciones', component: require(page+'text-page/terminos.vue').default, meta:{title:"Términos y condiciones"}},

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "Macro Plaza Las Palmas, Villa Hidalgo", filter: (title)=>{ return title+" - Macro Plaza Las Palmas, Villa Hidalgo"; } }
);

// export {routes};
export default MyRouter;
