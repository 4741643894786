<template lang="html">
  <div id="results-page">

    <section class="placed-backg mb-0 breadcrumb-banner-section">
      <div class="container oversized-container">
        <div class="box">
          <div class="page-breadcrumb">
            <ul>
              <li>Home</li>
              <li class="active">Restaurantes</li>
            </ul>
          </div>

          <div class="page-name">Restaurantes</div>
        </div>
      </div>
    </section>

    <section class="mb-4 banners-section">
      <!-- Desktop -->
      <swiper class="swiper-s2 d-none d-lg-block" :options="bannersOptions">
        <swiper-slide v-for="(banner,bnhInx) in banners.pc" :key="'bnhInx'+bnhInx">
          <a class="placed-backg d-block" :href="banner.url" v-bind:style="{ backgroundImage: 'url('+banner.image+')' }">
            <img class="w-100" src="public/images/pages/services/banner-resta-a.png">
          </a>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <!-- Mobile -->
      <swiper class="swiper-s2 d-lg-none" :options="bannersOptions">
        <swiper-slide v-for="(banner,bnhmInx) in banners.movil" :key="'bnhmInx-'+bnhmInx">
          <a class="placed-backg d-block" :href="banner.url" v-bind:style="{ backgroundImage: 'url('+banner.image+')' }">
            <img class="w-100" src="public/images/pages/services/banner-resta-a-m.png">
          </a>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="container oversized-container products-section">
      <div class="row align-items-start">

        <!-- Resultados en columnas -->
        <div class="col-lg">
          <div class="row sample-products-wrapper xxl-4e" v-if="!isLoading">
            <div class="col-sm-6 col-md-6 col-xl-4 sample-product shad-prod" v-for="(s, sInx) in stores" :key="'sInx-'+sInx">
              <router-link class="outlined-box" :to="'/servicios/restaurantes/'+$root._converToURL(s.name, s.id)">
                <div class="box-image">
                  <!-- <div class="promo">¡Con promociones!</div> -->

                  <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+s.img+')' }">
                    <img src="public/images/shared/empty-rec-1.png" >
                  </div>
                </div>

                <div class="box-shot-descr">
                  <h6 class="name">{{ s.name }}</h6>
                </div>
                <p class="p-button">
                  <a class="btn-more">Ver más</a>
                </p>
              </router-link>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-center" v-if="!stores.length && !isLoading">
              <h5>No se encontraron resultados</h5>
            </div>

            <div class="col-12" v-if="isLoading">
              <loading-component></loading-component>
            </div>
          </div>
        </div>
        <!--  -->

      </div>
    </section>

  </div>
</template>

<script>
import LoadingComponent from '../shared/loading-component.vue';
export default {
  data(){
    return{
      isLoading: false,
      showFilters: false,

      filtersForm: {
        keywords: '',
      },

      stores: [],

      banners: [],

      // == Carousel options ==
      bannersOptions: {
        loop: true,
        speed: 700,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },
      },
      // == ==
    }
  },

  methods: {
    onSubmitFilters(evt) {
      evt.preventDefault();
    },

    getStores: function(){
      this.isLoading = true;

      axios.get(tools.url('/api/services')).then((response)=>{
        this.stores = response.data.stores;
        this.banners = response.data.slider;
        this.isLoading = false;
      }).catch((error)=>{
        console.log(error);
      })
    },
  },

  mounted(){
    this.getStores();
  },

  components: {
    LoadingComponent
  },
}
</script>
