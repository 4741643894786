<template lang="html">
  <div id="description-page">
    <loading-component v-if="isLoading"></loading-component>

    <section class="breadcrumb-section" v-if="!isLoading">
      <div class="container oversized-container">
        <ul>
          <li>Home</li>
          <li>Servicios</li>
          <li class="active">{{ service.name }}</li>
        </ul>
      </div>
    </section>

    <section class="container oversized-container description-section" id="product-description" v-if="!isLoading">
      <div class="row">

        <div class="col-lg-7 col-gallery">
          <swiper class="swiper-top" :options="galleryOptions" ref="swiperTop">
            <swiper-slide v-for="(row, galIndex) in gallery" :key="galIndex">
              <div class="image-container">
                <div class="img">
                  <img class="placed-backg" src="public/images/shared/empty-rec-1.png" v-bind:style="{ backgroundImage: 'url('+row+')' }">
                  <!-- <v-zoom :img="row"></v-zoom> -->
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>

          <swiper class="swiper-th gallery-thumbs" :options="galleryOptionsThumbs" ref="swiperThumbs" v-if="galleryOptions.loopedSlides > 1">
            <swiper-slide v-for="(thb, galThIndex) in gallery" :key="'galThIndex'+galThIndex">
              <img class="placed-backg image" src="public/images/shared/empty-rec-1.png" v-bind:style="{ backgroundImage: 'url('+thb+')' }">
            </swiper-slide>
          </swiper>
        </div>

        <b-form class="col-lg-5 mt-3 mt-lg-0 col-description">
          <h1 class="name">{{ service.name }}</h1>

          <div class="mt-3 mb-2 extra-breadcrumb">
            <div><strong class="f-w-600">Categoria: </strong>
              <router-link class="buble mx-1" target="_blank" to="#" v-for="(row, index) in service.categories" :key="index">{{ row }}</router-link>
            </div>
          </div>
          <p class="mt-2 extra-breadcrumb" v-if="service.phones">
            <span><strong class="f-w-600">Teléfono: </strong> {{ service.phones }}<br /></span>
          </p>
          <p class="mt-2 extra-breadcrumb" v-if="service.whatsapp">
            <span><strong class="f-w-600">WhatsApp: </strong> {{ service.whatsapp }}<br /></span>
          </p>
          <p class="mt-2 extra-breadcrumb" v-if="service.location">
            <span><strong class="f-w-600">Ubicacion: </strong> {{ service.location }}<br /></span>
          </p>
          <p class="mt-2 extra-breadcrumb" v-if="service.paymentMethods">
            <span><strong class="f-w-600">Formas de pago: </strong> {{ service.paymentMethods }}<br /></span>
          </p>
          <p class="mt-2 extra-breadcrumb" v-if="service.facebook">
            <span><strong class="f-w-600">Facebook: </strong> <a :href="service.facebook">{{ service.facebook }}</a><br /></span>
          </p>
          <p class="mt-2 extra-breadcrumb" v-if="service.email">
            <span><strong class="f-w-600">Email: </strong> <a :href="'mailto:'+service.email">{{ service.email }}</a><br /></span>
          </p>
          <div class="mt-2 extra-breadcrumb" v-if="service.schedules">
            <div><strong class="f-w-600">Horario: </strong>
              <span class="d-block">{{ service.schedules }}</span>
            </div>
          </div>

           <h6 class="mt-3 subtitle" v-if="service.description">Descripción</h6>
          <div class="txt-description" v-if="service.description" v-html="service.description"></div>
        </b-form>
      </div>

    </section>

  </div>
</template>

<script>
import LoadingComponent from '../shared/loading-component.vue';
// import vZoom from 'vue-zoom';
export default {
  // components: {vZoom},

  data() {
    return {
      isLoading: true,
      id: null,
      service: [],
      services: [],
      gallery: [],

      // == Carrusel options ==
      galleryOptions: {
        loop: true,
        loopedSlides: 1,
        speed: 600,
        centeredSlides: true,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false
        // }
      },

      galleryOptionsThumbs: {
        loop: true,
        loopedSlides: 1,
        speed: 600,
        spaceBetween: 15,
        centeredSlides: true,
        slidesPerView: 3,
        touchRatio: 0.2,
        slideToClickedSlide: true,

        breakpoints: {
          1630: {
            slidesPerView: 5
          },
          768: {
            slidesPerView: 3
          },
          1: {
            slidesPerView: 2
          },
        }
      },
      // == ==
    }
  },

  watch: {
    "$route.params.id" : function(v){
      this.id = this.$root._getURLID(this.$route.params.id);
      this.getContent();
    }
  },

  methods:{
    runGalleryTh(){
      this.$nextTick(() => {
        const swiperTop = this.$refs.swiperTop.$swiper
        const swiperThumbs = this.$refs.swiperThumbs.$swiper
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop
      })
    },

    getContent(){
      axios.get(tools.url('/api/services/' + this.id)).then((response)=>{
        this.service = [];
        this.gallery = [];
        this.service = response.data;
        this.gallery = response.data.gallery;
        this.galleryOptions.loopedSlides = this.gallery.length;
        this.galleryOptionsThumbs.loopedSlides = this.gallery.length;

        setTimeout( ()=> {
          this.isLoading = false;
          this.runGalleryTh();
        }, 2000);

      }).catch((error)=>{
        console.log(error);
      });
    },
  },

   mounted(){
    this.id = this.$root._getURLID(this.$route.params.id);
    this.getContent();
  },

  components: {
    LoadingComponent
  },
}
</script>
